import type {
  Agreements_Agreement_Entry,
  Events_Event_Entry,
  Offers_Offer_Entry,
  OfficeMarket_Office_Entry,
  Pages_Page_Entry,
  Persons_Person_Entry,
  Posts_Post_Entry,
  Roomplanning_Reference_Entry,
  Services_Service_Entry,
} from '~~/src/gql/schema';
import { cmsPageData } from '../injectionSymbols';
import type { Result } from '../server/api/[site]/pageContent/[...uri].get';
import { useAreaStore } from '../stores/useArea';
import { useNav } from '../stores/useNav';
import { useCachedGet } from './dataFetching/genericFetchers';
import type { BasePageData } from '../server/transformers/page/basePage';
import { handleLoadingError } from '~/utils/handleLoadingError';

export type SubPage =
  | Posts_Post_Entry
  | Events_Event_Entry
  | Services_Service_Entry
  | Agreements_Agreement_Entry
  | Roomplanning_Reference_Entry
  | OfficeMarket_Office_Entry
  | Offers_Offer_Entry
  | Persons_Person_Entry;

export type CMSPage = Pages_Page_Entry | SubPage;

export type UnionCMSPage = Pages_Page_Entry &
  Partial<
    Posts_Post_Entry &
      Events_Event_Entry &
      Services_Service_Entry &
      Agreements_Agreement_Entry &
      Roomplanning_Reference_Entry &
      OfficeMarket_Office_Entry &
      Offers_Offer_Entry &
      Persons_Person_Entry
  >;

async function load(
  isLoading: Ref<boolean>,
  pageData: Ref<BasePageData>,
  uri: string,
  err: Ref<boolean>,
): Promise<Parameters<typeof navigateTo> | null> {
  isLoading.value = true;
  const site = useSiteIdent();
  try {
    const { data, error } = await useCachedGet<Result>(
      `/api/${site}/pageContent/${uri ? uri : 'startpage'}`,
    );

    if (error.value || (!data.value.redirect && !data.value.pageData)) {
      // eslint-disable-next-line no-console
      console.error('ERR: CMS Page load failed \n', error.value);
      err.value = true;
      return [{ path: '/404' }, { redirectCode: 404 }];
    }
    const redirect = data.value.redirect;

    if (redirect) {
      return [{ path: redirect }, { redirectCode: 301, external: true }];
    }

    if (data.value.pageData) {
      pageData.value = data.value.pageData;
      isLoading.value = false;
    } else {
      return [{ path: '/404' }, { redirectCode: 404 }];
    }
  } catch (e) {
    handleLoadingError(e);
    err.value = true;
  }
  return null;
}

export default async function useLoadCMSPage(initialRoute: string) {
  const pageData = ref<BasePageData | null>(null);
  const isLoading = ref(true);
  const error = ref(false);
  const router = useRouter();
  const navStore = useNav();
  const areaStore = useAreaStore();
  const pageUri = initialRoute.replace('/', '');

  provide(cmsPageData, pageData);

  const redirect = await load(isLoading, pageData, pageUri, error);

  /**
   * This prevents the navigation from jumping around when a user navigates in quick succession
   */
  if (
    !redirect &&
    pageData.value &&
    (pageUri == router.currentRoute.value.path.replace('/', '') ||
      import.meta.server)
  ) {
    navStore.mainNav_setActiveItemById(pageData.value.linkedNavItemId);
    areaStore.setActiveAreaById(getAreaId(pageData.value));
  }

  return { pageData, isLoading, redirect, error } as {
    pageData: Ref<BasePageData | null>;
    isLoading: Ref<boolean>;
    redirect: Parameters<typeof navigateTo> | null;
    error: Ref<boolean>;
  };
}

export function usePageData<T = BasePageData>() {
  return inject<Ref<T>>(cmsPageData);
}

function getAreaId(page: BasePageData) {
  if (!page) return null;
  if (page.area && page.area.id) {
    return page.area.id;
  } else {
    return null;
  }
}
